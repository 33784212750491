<template>
  <!-- <div class="data-source-container"> -->
  <div
    :class="`${
      sidebarHeightApplied
        ? 'data-source-container-sidebar'
        : 'data-source-container'
    }`"
  >
    <div v-if="label.length && showLabel" class="top-label">{{ label }}</div>
    <dropdown-wrap-vue @onClickOutside="onClickOutside">
      <div class="dropdown-wrap">
        <b-button
          :class="[
            'data-source-header',
            label.length && open ? 'active' : '',
            !label.length ? 'local-button-background' : '',
            disabled ? 'disabled' : ''
          ]"
          @click="openTab()"
        >
          <div class="button-container">
            <div
              :class="[
                'ellipsis',
                !label.length ? 'active-color' : '',
                placeholder == 'report' ? 'change-width' : ''
              ]"
            >
              {{
                localSelected && localSelected.name
                  ? localSelected.name
                  : placeholder
              }}
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" width="7.072" height="7.072" viewBox="0 0 7.072 7.072" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill="#a8aab7" d="M3.889 6.718a.5.5 0 01-.707 0L.353 3.889a.5.5 0 11.708-.707l2.474 2.475L6.01 3.182a.5.5 0 11.707.707z"/></svg>
          </div>
        </b-button>
        <div
          :class="[
            'data-source-abs',
            className,
            !label.length ? 'local-button' : ''
          ]"
          v-show="open"
        >
          <ul>
            <li v-if="clearSelection" @click="onClearSelection">
              <label for="">Clear selection</label>
            </li>
            <template v-for="(object, index) of list">
              <li :key="'A-' + index">
                <!-- <div class="tab-flex"> -->
                <label
                  :class="[
                    'custom-radio',
                    !label.length ? 'custom-checkbox-unique' : ''
                  ]"
                >
                  <input
                    type="radio"
                    :id="object"
                    @click="minimizeTab(index)"
                    :value="object"
                    v-model="localSelected"
                  />
                  <div class="radio-checkmark" v-if="showRadio"></div>
                  <p>{{ object.name }}</p>
                  <template v-if="showNested">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" v-if="
                        object.displayCategoryHierarchy && currentIndex != index
                      " v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147750" fill="none" d="M0 0h12v12H0z"/></g><g data-name="plus_icon"><path data-name="Path 12673" d="M6.51 6.51l3.84.022a.541.541 0 00.532-.458.489.489 0 00-.511-.584l-3.864.004.003-3.865a.489.489 0 00-.585-.51.541.541 0 00-.458.53l.021 3.84-3.84-.017a.541.541 0 00-.532.458.489.489 0 00.51.583h3.86v3.861a.489.489 0 00.584.51.541.541 0 00.458-.532z" fill="#7f8fa4"/></g></svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" v-if="
                        object.displayCategoryHierarchy && currentIndex == index
                      " v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g data-name="invisible box"><path data-name="Rectangle 147750" fill="none" d="M0 0h12v12H0z"/></g><g data-name="icons Q2"><path data-name="Path 12673" d="M6.51 6.51l3.84.022a.541.541 0 00.532-.458.489.489 0 00-.511-.584l-3.864.004h-1.02l-3.839-.022a.541.541 0 00-.532.458.489.489 0 00.51.583h3.86z" fill="#7f8fa4"/></g></svg>
                  </template>
                </label>
                <!-- </div> -->
              </li>
              <nested-multi-selected-vue
                v-if="
                  localSelected &&
                  localSelected.subscriptionId == object.subscriptionId &&
                  showNested
                "
                :openTab="currentIndex == index"
                :displayCategoryHierarchy="object.displayCategoryHierarchy"
                :parentSelected="localSelected"
                :list="object"
                :selected="selectedNestedData"
                @onChange="onChangeSubCategories"
                :key="'B-' + index"
              />
            </template>
          </ul>
        </div>
      </div>
    </dropdown-wrap-vue>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { analyticsEvents } from '../../constant/data'
import NestedMultiSelectedVue from '../../views/Admin/components/NestedSelection/NestedMultiSelected.vue'
import DropdownWrapVue from '../Common/DropdownWrap.vue'

/**
 * This component acts a single-selector dropdown. It is used in the sidepanel/global filters and on almost all the main pages as well.
 */
export default {
  name: 'SingleSelector',
  components: {
    DropdownWrapVue,
    NestedMultiSelectedVue
  },
  // props: [
  //   'list', 'selected', 'onChange', 'label', 'placeholder', 'onChangeNestedData', 'selectedNestedData', 'className', 'showNested', 'showRadio', 'clearSelection', 'updateAnalytics'],
  props: {
    /** This prop contains the list that is used to populate the dropdown */
    list: Array,
    /** This prop contains the information of selected object */
    selected: [Array, Object],
    /** This prop defines the behaviour when a new item is selected */
    onChange: Function,
    /** This prop contains/defines the text that appears as a label over the dropdown */
    label: String,
    /** This prop contains the text when no item has been selected */
    placeholder: String,
    /** This prop defines the behaviour when nested categories are selected/unselected */
    onChangeNestedData: Function,
    /** This list contains the values of nested data that have been selected */
    selectedNestedData: Array,
    /** This prop contains a class name for the dropdown */
    className: String,
    /** This prop indicates whether nested categories should be shown or not */
    showNested: Boolean,
    /** This prop indicates whether the custom radio icon should be present in the dropdown or not */
    showRadio: Boolean,
    /** This prop indicates whether the *Clear Selection* option should be shown as the first item in the dropdown */
    clearSelection: Boolean,
    /** This prop indicates whether the *updateAnalytics()* method should be present on this dropdown */
    updateAnalytics: Boolean,
    /** This prop indicates whether the dropdown is clickable or not */
    sidebarHeightApplied: Boolean,
    disabled: {
      type: Boolean,
      default: false
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      open: false,
      localSelected: {},
      currentIndex: null
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    selected() {
      this.localSelected = this.selected
    },
    localSelected() {
      if (this.list.length) {
        this.$emit('onChange', this.localSelected)
      }
    }
  },
  mounted() {
    this.localSelected = this.selected
  },
  methods: {
    ...mapActions('analytics', ['updateEvent']),
    /**
     * This method closes the dropdown if a click is detected anywhere outside the boundary of the dropdown menu
     * @public
     */
    onClickOutside(event) {
      this.open = false
      this.$emit('close')
    },
    /**
     * This method emits what new nested data (category) has been selected
     * @public
     */
    onChangeSubCategories(selected) {
      this.$emit('onChangeNestedData', selected)
    },
    /**
     * This method clears the selected item. (Resets it to an empty object)
     * @public
     */
    onClearSelection() {
      this.localSelected = {}
    },
    minimizeTab(index) {
      if (this.currentIndex === index) {
        this.currentIndex = null
      } else {
        this.currentIndex = index
      }
    },
    /**
     * This method opens the dropdown menu, and calls an *updateAnalytics()* method if the *updateAnalytics* prop is present
     * @public
     */
    openTab() {
      if (!this.disabled) {
        this.open = !this.open
      }
      if (this.open && this.updateAnalytics) {
        this.updateEvent({
          userId: this.userDetails.userId,
          event: 'click',
          label: analyticsEvents[this.label],
          pageUrl: window.location.href
        })
        this.$emit('open')
      } else {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss" src="./common.scss" scoped />
