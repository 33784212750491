<template>
    <div v-if="displayCategoryHierarchy && openTab" class="nested-check-box">
      <ul v-show="open && list.subCategory.length">
        <li v-for="(object, index) of list.subCategory" :key="index">
          <label :class="['custom-checkbox']">
            <input
              type="checkbox"
              :id="object"
              :value="object"
              v-model="localSelected" />
            <div class="checkmark"></div>
            <div class="line-block">
              <p>{{ object.name }}</p>
            </div>
          </label>
        </li>
      </ul>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
/** I'm unsure as to whether or not this component is being used at all */
export default {
  name: 'Product',
  // props: ['list', 'onChange', 'selected', 'displayCategoryHierarchy', 'parentSelected', 'openTab'],
  props: {
    /** This prop contains the data used to populate the dropdown */
    list: [Array, Object],
    /** This prop defines the behavior for when an option is selected or deselected */
    onChange: Function,
    /** This prop indicates what all options have been selected */
    selected: Array,
    /** This prop indicates whether category hierarchy should be displayed or not */
    displayCategoryHierarchy: Boolean,
    /** This prop indicates whether the parent is selected or not */
    parentSelected: Object,
    /** This prop indicates whether the dropdown is open or not */
    openTab: Boolean
  },
  data () {
    return {
      open: true,
      localSelected: [],
      isSelected: false
    }
  },
  computed: {
    ...mapState('user', ['userDetails'])
  },
  watch: {
    selected () {
      if (this.parentSelected.subscriptionId === this.list.subscriptionId) {
        this.localSelected = this.selected
      }
    },
    localSelected () {
      if (this.parentSelected.subscriptionId === this.list.subscriptionId) {
        this.$emit('onChange', this.localSelected)
      }
    }
  },
  mounted () {
    if (this.parentSelected.subscriptionId === this.list.subscriptionId) {
      if (JSON.parse(localStorage.getItem('SUB_CATEGORY_VERIFY')) !== null) {
        this.localSelected = JSON.parse(localStorage.getItem('SUB_CATEGORY'))
      } else {
        this.localSelected = this.list.subCategory
      }
    }
  },
  methods: {
    onClickOutside (event) {
      this.open = false
    }
  }
}
</script>

<style lang="scss" scoped>
.nested-check-box {
  ul {
    padding:0px 5px 0px 30px;
    li {
      padding: 5px 0px;
      label {
        font-size: 11px;
        line-height: 12px;
        p {
          font-family:Quicksand;
          color: #EFF3F6;
          padding-left: 10px;
        }
        .checkmark {
          min-width: 13px;
        }
      }
    }
  }
}

@media screen and (min-width: 1450px) and (max-width: 1700px) {
  .nested-check-box {
    ul {
      li {
        label {
          font-size: 12px;
          line-height: 15px;
        }
      }
    }
  }
}

@media screen and (min-width: 1700px) {
  .nested-check-box {
    ul {
      li {
        label {
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
</style>
